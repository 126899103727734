/**
 * Allow to hide / show content via checkbox
 * ======================================
 * - just add class js-togglecheckbox to parent which have inside checkbox
 * - specifi target element via data-toggle=".targetel"
 * - toggle hide class on target element
 */

var $el = $('.js-togglecheckbox')

$el.each(function(){
	var $this = $(this)
	var $checkbox = $this.find('input[type=checkbox]')
	var $target = $($this.attr('data-toggle'))

	$checkbox.change(function(){
		if($(this).is(':checked')){
			$target.removeClass('hide')
		}else{
			$target.addClass('hide')
		}
	})

})
