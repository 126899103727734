// js bundle order
// - import all files in plugins/
// - import all files in modules/
// - this file
import "./init";

import "./plugins/jquery.magnific-popup.min.js";
import "./plugins/jquery.selectric.min.js";
import "./plugins/slick.min.js";

import "./modules/animate.js";
import "./modules/canvasitem.js";
import "./modules/filteradvanced.js";
import "./modules/filterbartoggle.js";
import "./modules/listview.js";
import "./modules/offsetclick.js";
import "./modules/offsethover.js";
import "./modules/popups.js";
import "./modules/quotecarousel.js";
import "./modules/scrollStarted.js";
import "./modules/search.js";
import "./modules/selectric.js";
import "./modules/seriescarousel.js";
import "./modules/serieslist.js";
import "./modules/tippy.js";
import "./modules/toggle.js";
import "./modules/togglecheckbox.js";

import "./extra.js";
