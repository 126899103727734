/**
 * Toggle class on parent
 * ======================================
 * - or toggle class on element defined with html attribute `data-toggle`
 */

var $el = $('.js-toggle')

$el.click(function(e){
	var $el = $(this).parent()
	var $customParent = $($(this).attr('data-toggle'))
	var customClass = $(this).attr('data-toggleclass') ? $(this).attr('data-toggleclass') : 'is-toggled'

	if($customParent.length){
		$customParent.toggleClass(customClass)
	}else{
		$el.toggleClass(customClass)
	}

	e.preventDefault()
})

var $bg = $('.site__bg')
$bg.click(function(e){
	$bg.removeClass('is-active')
	$('.dropdown__parent').removeClass('is-toggled')
})

var $toggleBg = $('.js-toggle-bg')
$toggleBg.click(function(e){
	$bg.toggleClass('is-active')
	e.preventDefault()
})
