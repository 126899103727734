/**
 * Mobile filter toggle class on body
 * ======================================
 */


// open close
var $el = $('.js-filterbartoggle')

$el.click(function(e){
	var $el = $(this)
	var oldText = $el.children('span').html()
	var toggleText = $el.attr('data-toggle')

	$el.children('span').html(toggleText)
	$el.attr('data-toggle', oldText)
	$el.toggleClass('is-open')

	$('body').toggleClass('filterbar-is-open')
	e.preventDefault()
})


// open close items
var $el = $('.filterbar__nav__item__label')

$el.click(function(e){
	var $el = $(this)

	$el.parent().toggleClass('is-open')
	e.preventDefault()
})
