/**
 * Popup inits
 * ======================================
 * - jquery & magnific-popup needed
 */

const $autoopen = $('.js-popup-autoopen')
const $close = $('.js-popup-close')

$.extend(true, $.magnificPopup.defaults, {
	tClose: 'Zavřít (Esc)', // Alt text on close button
	tLoading: 'Načítám...', // Text that is displayed during loading. Can contain %curr% and %total% keys
	gallery: {
		tPrev: 'Předchozí (Šipka doleva)', // Alt text on left arrow
		tNext: 'Další (Šipka doprava)', // Alt text on right arrow
		tCounter: '%curr% z %total%' // Markup for "1 of 7" counter
	},
	image: {
		tError: '<a href="%url%">Fotku</a> se nepodařilo nahrát.' // Error message when image could not be loaded
	},
	ajax: {
		tError: '<a href="%url%">Obsah</a> se nepovedlo načíst.' // Error message when ajax request failed
	}
});

if($autoopen.length){
	$.magnificPopup.open({
	  items: {
	    src: '.js-popup-autoopen'
	  },
	  type: 'inline',
	  mainClass: 'mfp-fade',
	  removalDelay: 300,
	  showCloseBtn: false
	})
}

$('.js-popup').magnificPopup({
  type: 'inline',
  mainClass: 'mfp-fade',
  removalDelay: 300,
  showCloseBtn: false
});


$('.js-gallery').magnificPopup({
	delegate: 'a',
  type: 'image',
  mainClass: 'mfp-fade',
  removalDelay: 300,
  showCloseBtn: false,
  gallery: {
  	enabled: true
  }
});


$(document).on('click', '.js-popup-close', function(){
	$.magnificPopup.close()
})
