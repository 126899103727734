/**
 * Listview hover & carousel
 * ======================================
 * - http://kenwheeler.github.io/slick/
 */

var $el = $('.js-listviewcarousel')

$el.each(function(){
	var $this = $(this)
	var $arrows = $this.closest('.carousel').find('.js-listviewcarousel-arrows').children()

	$this.slick({
		dots: true,
	  infinite: false,
	  slidesToShow: 1,
	  slidesToScroll: 1,
	  prevArrow: $arrows.first(),
	  nextArrow: $arrows.last()
	})

})

$("body").on("mouseenter",".listview__item__img", function(){
	console.log(1);
	$(this).find(".js-listviewcarousel-arrows, .slick-dots").css("opacity", "1");
});

$("body").on("mouseleave",".listview__item__img", function(){
	console.log(0);
	$(this).find(".js-listviewcarousel-arrows, .slick-dots").css("opacity", "0");
});