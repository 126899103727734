/*
	@ Add body class if user scrolled
*/
var enableOn = window.innerWidth > 300
var start = 1

var scrollStarted = function() {
	var top = window.pageYOffset
  if(top >= start){
  	document.body.classList.add('is-scrolled')
  }else{
  	document.body.classList.remove('is-scrolled')
  }
}

if(enableOn){
	window.addEventListener('scroll', function() {
    window.requestAnimationFrame(scrollStarted)
	});
	// initial call
	scrollStarted()
}
