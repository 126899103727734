/**
 * Extra javascript which cannot be included to index.js
 *
 */

/**
 * Handles forms in popups
 * @param selector
 */
function ajaxForm(selector) {
    $(selector).submit(function (e) {
        e.preventDefault();
        replaceCSRFToken();

        var $form = $(this);

        $.ajax({
            type: 'POST',
            url: $form.attr('action'),
            data: $form.serialize(),

            success: function (data) {
                // console.log(data);
                // $.magnificPopup.close();
                document.location = data.success_url;
            },

            error: function (data) {
                $(selector).find('.field').removeClass('fieldrow__item--error');
                $(selector).find('.field__error').remove();
                $(selector).find('.tip').remove();
                $(selector).find('label.checkbox').removeClass('checkbox--error');

                for (var field in data.responseJSON.errors) {
                    if (field == '__all__') {
                        var nonFieldError = '<div class="tip tip--red tip--full"><svg class="icon icon-tip"><use xlink:href="#icon-tip"></use></svg><div class="tip--text"></div></div>';

                        for (var i in data.responseJSON.errors.__all__) {
                            var $error = $(nonFieldError);
                            $error.find('.tip--text').text(data.responseJSON.errors.__all__[i]);
                            $(selector).prepend($error);
                        }
                    }

                    var $input = $(selector).find('input[name=' + field + ']');

                    if ($input.attr('type') === 'checkbox') {
                        $input.parents('label').addClass('checkbox--error');
                        $input.parents('label').append('<div class="field__error">' + data.responseJSON.errors[field][0] + '</div>');
                    } else {
                        $input.parents('.field').addClass('fieldrow__item--error');
                        $input.parent().append('<div class="field__error">' + data.responseJSON.errors[field][0] + '</div>');
                    }
                }
            }
        });
    });
}


function seriesReinitSlick($container) {
    $container.find('.js-listviewcarousel').each(function () {
        var $this = $(this);
        var $arrows = $this.closest('.carousel').find('.js-listviewcarousel-arrows').children();
        $this.slick({
            dots: true,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: $arrows.first(),
            nextArrow: $arrows.last()
        });
    });
}


function seriesReinitHover($container) {
    var $el = $container.filter('.js-offsethover');

    $el.hover(function () {
        var $el = $(this);
        var hiddenRight = $el.find('.js-offsethover-target').offset().left + $el.find('.js-offsethover-target').outerWidth();
        var limit = $(document).outerWidth();

        if (hiddenRight > limit) {
            $el.addClass('offsethover-is-active');
        }

        $el.addClass('is-hover');
    }, function () {
        var $el = $(this);
        $el.removeClass('is-hover');
    });
}


function seriesReinitPopup($container) {
    /**
     * Popup inits
     * ======================================
     * - jquery & magnific-popup needed
     */

    var $close = $container.find('.js-popup-close');

    $container.find('.js-popup').magnificPopup({
        type: 'inline',
        mainClass: 'mfp-fade',
        removalDelay: 300,
        showCloseBtn: false
    });
}


function tippyReinit($newItems) {
    $newItems.filter((ix, item) => (item.tagName)).each((idx, node) => {
        node.querySelectorAll('[data-tippy]').forEach(node => {
            tippy(node, {content: node.dataset.tippy})
        })
    })
}


function seriesLoader(baseUrl, queryParams) {
    var page = 0;

    $('#series-more a').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $('body').addClass('is-loading');

        $.ajax({
            type: 'GET',
            url: baseUrl.replace('/0/', '/' + page + '/') + '?' + queryParams,

            success: function (data) {
                var $newItems = $(data.content);

                seriesReinitSlick($newItems);
                // seriesReinitHover($newItems);
                seriesReinitPopup($newItems);

                $newItems.insertBefore($('#series-more'));

                if (data.next_page === false) {
                    $('#series-more').hide();
                } else {
                    page = data.next_page;
                }

                // initialize carousel
            },
            complete: function () {
                $('body').removeClass('is-loading');
            }
        });
    })
}


function resourceLoader(baseUrl) {
    var page = 0;

    $('#resource-more a').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $('body').addClass('is-loading');

        $.ajax({
            type: 'GET',
            url: baseUrl.replace('/0/', '/' + page + '/'),

            success: function (data) {
                var $newItems = $(data.content);
                var $container = $('#resource-more').parent().find('section');

                $container.append($newItems);

                if (data.next_page === false) {
                    $('#resource-more').hide();
                } else {
                    page = data.next_page;
                }
            },
            complete: function () {
                $('body').removeClass('is-loading');
            }
        });
    })
}


function searchSeriesLoader(baseUrl) {
    var page = 0;

    $('#search-series-more a').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $('body').addClass('is-loading');

        $.ajax({
            type: 'GET',
            url: baseUrl.replace('/0/', '/' + page + '/'),

            success: function (data) {
                var $newItems = $(data.content);
                var $container = $('#search-series-more').parent().find('section');

                $('#series-count').text(
                    parseInt($('#series-count').text()) + data.count
                );

                seriesReinitSlick($newItems);
                seriesReinitHover($newItems);
                seriesReinitPopup($newItems);

                $container.append($newItems);

                if (data.next_page === false) {
                    $('#search-series-more').hide();
                } else {
                    page = data.next_page;
                }
            },
            complete: function () {
                $('body').removeClass('is-loading');
            }
        });
    })
}


function searchTilesLoader(baseUrl) {
    var page = 0;

    $('#search-tiles-more a').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $('body').addClass('is-loading');

        $.ajax({
            type: 'GET',
            url: baseUrl.replace('/0/', '/' + page + '/'),

            success: function (data) {
                var $newItems = $(data.content);
                var $container = $('#search-tiles-more').parent().find('.serieslist');

                $('#tiles-count').text(
                    parseInt($('#tiles-count').text()) + data.count
                );

                $container.append($newItems);

                seriesReinitHover($newItems);
                seriesReinitPopup($newItems);
                tippyReinit($newItems);

                if (data.next_page === false) {
                    $('#search-tiles-more').hide();
                } else {
                    page = data.next_page;
                }
            },
            complete: function () {
                $('body').removeClass('is-loading');
            }
        });
    })
}


function getCookie(name) {
    var cookieValue = null;

    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');

        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);

            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}


function replaceCSRFToken() {
    $('[name=csrfmiddlewaretoken]').val(getCookie('csrftoken'));
}


function events($obj) {
    $obj.on('click', 'a[href="#wishlist-edit-popup"]', function () {
        var name = $(this).attr('data-name');
        var formAction = $(this).attr('data-form-action');
        $('#wishlist-edit-popup form').attr('action', formAction);
        $('#wishlist-edit-popup input[name="name"]').val(name);
    });

    $obj.on('click', 'a[href="#wishlist-tile-add-popup"]', function () {
        var item = $(this).attr('data-item');
        $('#wishlist-tile-add-popup input[name="item"]').val(item);
    });

    $obj.on('click', 'a[href="#wishlist-series-add-popup"]', function () {
        console.log('here');
        var item = $(this).attr('data-item');
        $('#wishlist-series-add-popup input[name="item"]').val(item);
    });

    $obj.on('click', 'a[href="#wishlist-interior-add-popup"]', function () {
        var item = $(this).attr('data-item');
        $('#wishlist-interior-add-popup input[name="item"]').val(item);
    });

    $obj.on('click', '.add-new-wishlist-from-adding', function () {
        var itemType = $(this).attr('data-item-type');
        if (itemType) {
            var itemId = $(this).parents('form').find('input[name="item"]').val();

            $('#wishlist-add-popup input[name="item_type"]').val(itemType);
            $('#wishlist-add-popup input[name="item_id"]').val(itemId);
        }

        // $.magnificPopup.close();
        $.magnificPopup.open({
            items: {
                src: '#wishlist-add-popup',
                type: 'inline'
            }
        });
    });
}


$(function () {
    ajaxForm('#sign-up-popup form');
    ajaxForm('#sign-in-popup form');
    ajaxForm('#wishlist-add-popup form');
    ajaxForm('#wishlist-edit-popup form');
    ajaxForm('#wishlist-tile-add-popup form');
    ajaxForm('#wishlist-series-add-popup form');
    ajaxForm('#wishlist-interior-add-popup form');

    events($('body'));

    var clipboard = new ClipboardJS('#copy-wishlist');
    clipboard.on('success', function (e) {
        alert('Odkaz na wishlist byl úspěšně zkopírován do clipboardu');
    });

    $('a.backlink').click(function (e) {
        e.preventDefault();
        window.history.back();
    });


    if (typeof SERIES_LOADER_BASE_URL !== 'undefined' && typeof SERIES_LOADER_QUERY_PARAMS !== 'undefined') {
        seriesLoader(SERIES_LOADER_BASE_URL, SERIES_LOADER_QUERY_PARAMS);
    }

    if (typeof RESOURCE_LOADER_BASE_URL !== 'undefined') {
        resourceLoader(RESOURCE_LOADER_BASE_URL);
    }

    if (typeof SEARCH_SERIES_LOADER_BASE_URL !== 'undefined') {
        searchSeriesLoader(SEARCH_SERIES_LOADER_BASE_URL);
    }

    if (typeof SEARCH_TILES_LOADER_BASE_URL !== 'undefined') {
        searchTilesLoader(SEARCH_TILES_LOADER_BASE_URL);
    }
});
