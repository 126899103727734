/**
 * Advanced filter toggle class on body
 * ======================================
 * - and show more / less checkboxes
 */


// open close
var $el = $('.js-filteradvanced')

$el.click(function(e){
	$('body').toggleClass('filteradvanced-is-open')
	e.preventDefault()
})


// more, less toggle
var $el = $('.js-filteradvanced-more')

$el.click(function(e){
	var $el = $(this)
	var $parent = $el.closest('.filteradvanced__group')
	var $items = $parent.find('.checkbox.is-toggled')

	var oldText = $el.children('span').html()
	var toggleText = $el.attr('data-toggle')

	$el.children('span').html(toggleText)
	$el.attr('data-toggle', oldText)

	$items.toggleClass('is-hidden')
	e.preventDefault()
})

//uncheck all
var $el = $('.js-filteradvanced-uncheck')

$el.click(function(e){
	var $el = $(this)
	var $parent = $('.filteradvanced')
	var $items = $parent.find('input[type=checkbox]')

	$items.prop('checked', false)
	e.preventDefault()
})
