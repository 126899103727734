/**
 * Toggle class on click and determine if hidden element overflow document
 * ======================================
 * - add class `is-open` on hover (use it in css)
 * - add class `offsetclick-is-active` if overflow on right side of document
 * - it is necessary use opacity hide instead of display:none on `.js-canvasitem-target`!
 */

var $el = $('.js-canvasitem')
var $elBg = $('.interier__canvas__bg')
var $elAll = $('.js-canvasitem-parent')

$el.click(function(){
	var $el = $(this)
	var $parent = $el.closest('.js-canvasitem-parent')
	var hiddenLeft = $parent.find('.js-canvasitem-target').offset().left
	var hiddenTop = $parent.find('.js-canvasitem-target').offset().top
	var hiddenBottom = $parent.find('.js-canvasitem-target').offset().top + $parent.find('.js-canvasitem-target').outerHeight()

	$elAll.not($parent).removeClass('is-open')

	if(hiddenLeft < 0){
		$parent.addClass('offsetclick-is-active')
	}

	if(hiddenBottom > ($elBg.outerHeight() + $elBg.offset().top)){
		$parent.addClass('offsetclickbottom-is-active')
	}

	$parent.toggleClass('is-open')
})


$elBg.click(function(){
	$elAll.removeClass('is-open')
})
