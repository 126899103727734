/**
 * Toggle class on click and determine if hidden element overflow document
 * ======================================
 * - add class `is-open` on hover (use it in css)
 * - add class `offsetclick-is-active` if overflow on right side of document
 * - it is necessary use opacity hide instead of display:none on `.js-offsetclick-target`!
 */

var $el = $('.js-offsetclick')

$el.click(function(){
	var $el = $(this).parent()
	var hiddenLeft = $el.find('.js-offsetclick-target').offset().left

	if(hiddenLeft < 0){
		$el.addClass('offsetclick-is-active')
	}

	$el.toggleClass('is-open')
})
