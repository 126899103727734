/**
 * Reference carousel
 * ======================================
 * - http://kenwheeler.github.io/slick/
 */

var $el = $('.js-quotecarousel')
var $arrows = $('.js-quotecarousel-arrows').children()

$el.slick({
  infinite: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  prevArrow: $arrows.first(),
  nextArrow: $arrows.last(),
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2
      }
    }
   ]
});
