import tippy from 'tippy.js';

tippy.setDefaults({
	arrow: true,
	delay: 40,
	theme: 'my-tippy',
	animation: 'fade',
	duration: [250, 20],
	maxWidth: 200,
	interactive: true,
	onShow: function(e){
		var $parent = e.reference.closest('.serieslist__item')

		if($parent !== null){
			$parent.classList.add('is-tippy-hover', 'is-hover', 'is-hoverdelay')
		}
	},
	onHide: function(e){
		var $parent = e.reference.closest('.serieslist__item')

		if($parent !== null){
			$parent.classList.remove('is-tippy-hover')
			$parent.classList.add('is-hover', 'is-hoverdelay')
		}
	}
})
