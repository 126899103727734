/**
 * Reference carousel
 * ======================================
 * - http://kenwheeler.github.io/slick/
 */

var $el = $('.js-seriescarousel')

$el.each(function(){
	var $this = $(this)
	var $arrows = $this.parent().parent().find('.js-seriescarousel-arrows')

	$this.slick({
	  infinite: false,
	  slidesToShow: 4,
	  variableWidth: true,
	  slidesToScroll: 1,
	  prevArrow: $arrows.find('.prev'),
	  nextArrow: $arrows.find('.next'),
	  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 1
      }
    }
   ]
	});
})
