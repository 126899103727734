/**
 * This script must be load first
 * @type {XMLHttpRequest}
 */
var ajax = new XMLHttpRequest();

ajax.open("GET", SPRITE_URL, true);
ajax.send();
ajax.onload = function(e) {
    var div = document.createElement("div");
    div.innerHTML = ajax.responseText;
    document.body.insertBefore(div, document.body.childNodes[0]);
};

function runAnimation(timeout) {
    setTimeout(function() {
        document.documentElement.className += ' js run-animation';
    }, timeout)
}

runAnimation(400);