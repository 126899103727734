/**
 * Search
 * ======================================
 * - jquery & magnific-popup needed
 */

$(document).on('click', '.js-searchtoggle', function(){
	$(this).parent().toggleClass('is-open')
	$(this).parent().find('input').first().focus()
})
