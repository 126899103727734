/**
 * Add hover class and determine if hidden element overflow document
 * ======================================
 * - add class `is-hover` on hover (use it in css)
 * - add class `offsethover-is-active` if overflow on right side of document
 * - delay support for smooth css transitions
 * - it is necessary use opacity hide instead of display:none on `.js-offsetclick-target`!
 */

var $el = $('.js-offsethover')
var zindexbase = 50

$el.hover(function(){
	var $el = $(this)
	var delay = $(this).attr('data-hoverdelay')
	var hiddenRight = $el.find('.js-offsethover-target').offset().left +  $el.find('.js-offsethover-target').outerWidth()
	var limit = $(document).outerWidth()

	if(hiddenRight > limit){
		$el.addClass('offsethover-is-active')
	}

	$el.addClass('is-hover')

	zindexbase++

	if(delay){
		$el.addClass('is-hoverdelay').css('z-index', zindexbase)
	}
},function(){
	var $el = $(this)
	var delay = $(this).attr('data-hoverdelay')

	if(delay){
		$el.removeClass('is-hoverdelay')

		setTimeout(function(){
			$el.removeClass('is-hover')
		}, delay)
	}else{
		$el.removeClass('is-hover')
	}
})
